import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DatePicker, { registerLocale } from "react-datepicker";
import { Form, Formik } from "formik";
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";
import * as yup from "yup";
import ptBR from "date-fns/locale/pt-BR";
import {
  ArrowPathIcon,
  CalendarDaysIcon,
  CalendarIcon,
  BuildingOffice2Icon,
  CheckCircleIcon,
  TrashIcon,
  ArchiveBoxIcon,
  PlusIcon,
  XMarkIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { useMediaQuery } from "react-responsive";

import /*  FunnelIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CheckIcon, */

  "@heroicons/react/24/solid";
/* import { Checks } from "@phosphor-icons/react"; */

import { Input } from "./Input";

import styles from "./styles.module.css";
import "react-datepicker/dist/react-datepicker.css";
/* import { SelectWithRangeInput } from "./SelectWithRangeInput"; */

import { ErrorInfo } from "react-dom/client";

import Api from "../../../../helpers/Api";
import { selectUser } from "../../../../store/slices/userSlice";
import { useNavigate } from "react-router";
import { SearchInput } from "../../components/SearchInput";

interface FiltersProps {
  examsRequest: any[];
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  datePeriodRange: [undefined | Date, undefined | Date];
  setDatePeriodRange: React.Dispatch<React.SetStateAction<[undefined | Date, undefined | Date]>>;
  selectedSituation: any;
  setSelectedSituation: React.Dispatch<any>;
  selectedClinic: any;
  setSelectedClinic: React.Dispatch<any>;
}

export function Filters({
  search,
  setSearch,
  examsRequest,
  datePeriodRange,
  setDatePeriodRange,
  selectedSituation,
  setSelectedSituation,
  selectedClinic,
  setSelectedClinic,
}: FiltersProps) {
  registerLocale("pt-BR", ptBR);
  const { user } = useSelector(selectUser);
  const idUser = user[0].idUser;
  const [showFilters, setShowFilters] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [clinics, setClinics] = useState([]);
  const [dentists, setDentists] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchClinics = async () => {
      try {
        const clinicsResponse = await Api.getClinics(idUser);
        setClinics(clinicsResponse.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchClinics();
  }, [user]);

  useEffect(() => {
    const fetchDentists = async () => {
      try {
        const dentistsResponse = await Api.GetUserClinicaByUserId(idUser);
        if (dentistsResponse.isSuccess) {
          setDentists(dentistsResponse.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchDentists();
  }, [user]);

  const selectStyles = {
    container: (baseStyles, state) => ({
      ...baseStyles,
      width: "100%",
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      width: "100%",
      backgroundColor: "#e6e6e6",
      paddingLeft: "44px",
      borderRadius: "12px",
      alignItems: "center",
      gap: "8px",
      border:
        state.isFocused || state.isHover || state.isHovered ? "none" : "none",
      boxShadow: "none",
      "&:hover": {
        borderColor: "none",
      },
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      zIndex: "11",
      borderRadius: "0px 0px 12px 12px",
      border: "1px solid #D3D3D3",
      boxShadow: "none",
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: state.isFocused || state.isSelected ? "#E4E4E4" : "",
      fontWeight: state.isSelected || state.isFocused ? "600" : "400",
      margin: "0px auto",
      width: "99%",
      borderRadius: "12px",
    }),
    dropdownIndicator: (baseStyles, state) => ({
      ...baseStyles,
      cursor: "pointer",
      transform: `${state.isFocused ? "rotate(-180deg)" : ""}`,
    }),
    clearIndicator: (baseStyles, state) => ({
      ...baseStyles,
      display: "none",
    }),
    indicatorSeparator: (baseStyles, state) => ({
      ...baseStyles,
      display: "none",
    }),
    valueContainer: (baseStyles, state) => ({
      ...baseStyles,
      padding: "0px",
      color: "hsl(0, 0%, 50%)",
    }),
  };

  const selectDateStyles = {
    ...selectStyles,
    menu: (baseStyles, state) => ({
      display: "none",
    }),
  };

  const uniqueValues = (key: string) => {
    return Array.from(new Set(examsRequest.map((a) => a[key]))).map(
      (uniqueKey) => examsRequest.find((a) => a[key] === uniqueKey)
    );
  };

  const dateUniqueValues = uniqueValues("dateSend");

  const dateOptions = dateUniqueValues.map((value) => {
    return {
      value: new Date(value.dateSend).toLocaleDateString("pt-BR"),
      label: new Date(value.dateSend).toLocaleDateString("pt-BR"),
    };
  });

  /*   const situationUniqueValues = uniqueValues("situation");
    const situationOptions = situationUniqueValues.map((value) => {
      return {
        value: value?.situation,
        label: value?.situation,
      };
    }); */

  const clinicOptions = clinics.map((clinic) => {
    return {
      value: clinic?.clinicalName,
      label: clinic?.clinicalName,
    };
  });

  const dentistOptions = clinics.map((clinic) => {
    /*     return {
      value: dentist?.clinicalName,
      label: dentist?.clinicalName,
    }; */
  });

  const formRef = useRef<any>();

  const isMobileScreen = useMediaQuery({ query: "(max-width: 880px)" });

  const hash = user.hash;

  const redirectToEditor = () => {
    navigate("/dashboard/requisição-exames/criar");
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronDownIcon
          width={12}
          height={12}
          stroke="#535353"
          fontWeight={900}
        />
      </components.DropdownIndicator>
    );
  };

  const ValueContainer = (props) => {
    return (
      <components.ValueContainer {...props}>
        {props.selectProps.placeholder}
      </components.ValueContainer>
    );
  };

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleDeleteSearch = () => {
    setSearch("");
  };

  const handleChangeSituation = (value) => {
    setSelectedSituation(value);
  };

  const resetSelectedSituation = () => {
    setSelectedSituation(null);
  };

  const handleChangeClinic = (value) => {
    setSelectedClinic(value);
  };

  const resetSelectedClinic = () => {
    setSelectedClinic(null);
  };

  const handleButtonClick = (daysOffset) => {
    const currentDate = new Date();
    const startDate = new Date();
    startDate.setDate(currentDate.getDate() - Math.abs(daysOffset));
    const endDate = new Date();
    setDatePeriodRange([startDate, endDate]);
    setShowFilters(true);
  };

  const resetDatePeriodRange = () => {
    setDatePeriodRange([undefined, undefined]);
  };

  const isDate = (date: any) => {
    return date instanceof Date;
  };

  const formatDate = (date: Date) => {
    return new Date(date).toLocaleDateString("pt-BR");
  };

  const isMobileDimension = useMediaQuery({ query: '(max-width: 600px)' });

  return (
    <div
      className={showFilters ? `${styles.wrapper}` : `${styles.wrapper_active}`}
    >
      <span className={styles.respostaFilter}>
        <Formik
          initialValues={{
            title: "",
            clinic: "",
            period: "",
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={() => { }}
          innerRef={formRef}
        >
          {({ errors, isSubmitting, values, setFieldValue }) => (
            <Form
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
              className={styles.form}
            >
              <div className={styles.containerInputs}>
                <Input
                  value={search}
                  name="title"
                  type="text"
                  placeholder="Buscar"
                  onChange={(e) => {
                    handleChangeSearch(e);
                  }}
                  loadingicon={searchLoading}
                  handleDeleteSearch={handleDeleteSearch}
                />

                {/*                  {!isMobileScreen && (
                  <div className={styles.selectWrapper}>
                    <CheckCircleIcon
                      className={styles.selectIcon}
                      width={20}
                      height={20}
                      color="#535353"
                    />
                    <Select
                      name="clinic"
                      className={styles.selectOption}
                      placeholder="Situação"
                      styles={selectStyles}
                      options={situationOptions}
                      onChange={handleChangeSituation}
                      components={{ DropdownIndicator, ValueContainer }}
                    />
                  </div>
                )} */}

                {!isMobileScreen && (
                  <div className={styles.selectWrapper}>
                    <BuildingOffice2Icon
                      className={styles.selectIcon}
                      width={20}
                      height={20}
                      color="#535353"
                    />
                    <Select
                      name="clinic"
                      className={styles.selectOption}
                      placeholder="Clinica"
                      styles={selectStyles}
                      options={clinicOptions}
                      onChange={handleChangeClinic}
                      components={{ DropdownIndicator, ValueContainer }}
                    />
                  </div>
                )}

                {/*                 {!isMobileScreen && (
                  <div className={styles.selectWrapper}>
                    <CalendarIcon
                      className={styles.selectIcon}
                      width={20}
                      height={20}
                      color="#535353"
                    />
                    <Select
                      name="clinic"
                      className={styles.selectOption}
                      placeholder="Dentista"
                      styles={selectStyles}
                      components={{ DropdownIndicator, ValueContainer }}
                    />
                  </div>
                )} */}

                {/*                 {!isMobileScreen && (
                  <div className={styles.selectWrapper}>
                    <CalendarDaysIcon
                      className={styles.selectIcon}
                      width={20}
                      height={20}
                      color="#535353"
                    />
                    <Select
                      onChange={handleChangeDate}
                      name="color"
                      placeholder="Data"
                      styles={selectStyles}
                      options={dateOptions}
                      components={{ DropdownIndicator }}
                    />
                    <div></div>
                  </div>
                )} */}

                <div className={styles.selectWrapper}>
                  <CalendarDaysIcon
                    className={styles.selectIcon}
                    width={20}
                    height={20}
                    color="#535353"
                  />
                  <DatePicker
                    startDate={datePeriodRange[0]}
                    endDate={datePeriodRange[1]}
                    onChange={(update: any) => {
                      setDatePeriodRange(update);
                    }}
                    selectsRange
                    placeholderText="Data"
                    wrapperClassName={styles.datePicker}
                    className={styles.selectOption}
                    monthsShown={2}
                    calendarClassName="custom-calendar"
                    locale="pt-BR"
                    customInput={
                      <Select
                        name="period"
                        placeholder="Data"
                        styles={selectDateStyles}
                        components={{ DropdownIndicator }}
                      />
                    }
                    renderCustomHeader={({
                      monthDate,
                      customHeaderCount,
                      decreaseMonth,
                      increaseMonth,
                    }) => (
                      <div>
                        <button
                          aria-label="Previous Month"
                          className={
                            "react-datepicker__navigation react-datepicker__navigation--previous"
                          }
                          style={
                            customHeaderCount === 1
                              ? { visibility: "hidden" }
                              : null
                          }
                          onClick={decreaseMonth}
                        >
                          <span
                            className={
                              "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                            }
                          >
                            {"<"}
                          </span>
                        </button>
                        <span className="react-datepicker__current-month">
                          {monthDate.toLocaleString("pt-BR", {
                            month: "long",
                            year: "numeric",
                          })}
                        </span>
                        <button
                          aria-label="Next Month"
                          className={
                            "react-datepicker__navigation react-datepicker__navigation--next"
                          }
                          style={
                            customHeaderCount === 0
                              ? { visibility: "hidden" }
                              : null
                          }
                          onClick={increaseMonth}
                        >
                          <span
                            className={
                              "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                            }
                          >
                            {">"}
                          </span>
                        </button>
                      </div>
                    )}
                  >
                    <div className={styles.container_buttons}>
                      <button onClick={() => handleButtonClick(-1)}>
                        Ontem
                      </button>
                      <button onClick={() => handleButtonClick(-7)}>
                        Últimos 7 dias
                      </button>
                      <button onClick={() => handleButtonClick(-15)}>
                        Últimos 15 dias
                      </button>
                      <button onClick={() => handleButtonClick(-30)}>
                        Últimos 30 dias
                      </button>
                    </div>
                  </DatePicker>
                </div>
              </div>
              <div className={styles.wrapperButtons}>
                {/*                 <div className={styles.containerButton}>
                  <button className={styles.button} onClick={() => {}}>
                    <ArchiveBoxIcon width={20} />
                    Ver arquivados
                  </button>
                </div> */}

                {!isMobileDimension && (
                  <div className={styles.containerButton}>
                    <button className={styles.button} onClick={redirectToEditor}>
                      <PlusIcon width={20} />
                      Nova Requisição
                    </button>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </span>

      <div className={`${styles.cardsContainer}`}>
        {selectedClinic && (
          <div className={styles.selectedCard}>
            Clínica: {selectedClinic.label}
            <XMarkIcon
              onClick={resetSelectedClinic}
              width={16}
              color="#006E5A"
            />
          </div>
        )}

        {selectedSituation && (
          <div className={styles.selectedCard}>
            Situação: {selectedSituation.label}
            <XMarkIcon
              onClick={resetSelectedSituation}
              width={16}
              color="#006E5A"
            />
          </div>
        )}

        {datePeriodRange.length >= 1 && isDate(datePeriodRange[0]) && (
          <div className={styles.selectedCard}>
            Data realização: {formatDate(datePeriodRange[0])}
            {" - "}
            {datePeriodRange[1] &&
              isDate(datePeriodRange[1]) &&
              formatDate(datePeriodRange[1])}
            <XMarkIcon
              onClick={resetDatePeriodRange}
              width={16}
              color="#006E5A"
            />
          </div>
        )}

        {(search ||
          (datePeriodRange.length > 0 && isDate(datePeriodRange[0])) ||
          selectedSituation ||
          selectedClinic) && (
            <div
              className={styles.clearCard}
              onClick={() => {
                search && handleDeleteSearch();
                datePeriodRange.length > 0 && resetDatePeriodRange();
                selectedSituation && resetSelectedSituation();
                selectedClinic && resetSelectedClinic();
              }}
            >
              <TrashIcon width={20} color="535353" />
              Limpar Tudo
            </div>
          )}
      </div>
    </div>
  );
}
