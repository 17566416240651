import { Skeleton } from "@mui/material"

export const TableSkeleton = () => {
  return (
    <>
    <div style={{ height: "90px" }}>
      <Skeleton height={"100%"} />
    </div>
    {Array.from({ length: 15 }, (_, i) => (
      <div key={i} style={{ height: "50px" }}>
        <Skeleton width={"100%"} height={"90%"} />
      </div>
    ))}
  </>
  )
}