import { Heading } from '@cdt-ui/react'
import styles from './styles.module.css'

export function BoxVideoInstitutional() {
    return (
        <div className={styles.wrapper}>
            {/* <div className={styles.container_video}>
                <iframe
                    id="panda-87ecb9fe-f17d-41d7-942a-877e56c709e6"
                    width="560"
                    height="1"
                    className={styles.video}
                    src="https://player-vz-fb154304-6eb.tv.pandavideo.com.br/embed/?v=c79a13ef-c847-49ba-965c-62a1cad4595c"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
                    allowFullScreen
                >
                </iframe>


            </div> */}

            <div className={styles.container_content}>
                <Heading as="h2">
                    Bem-vindo à Serodonto!
                </Heading>
                <p>
                    A plataforma de gestão odontológica definitiva para transformar o seu consultório num negócio lucrativo.
                </p>
            </div>

        </div>
    )
}