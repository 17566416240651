import { getAllModels } from "../../utils/apiConnection";
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

import styles from './styles.module.css'
import { SearchInput } from "../../components/SearchInput";

export function ListOfModels() {
    const location = useLocation();

    const [searchTerm, setSearchTerm] = useState("");
    const [modelsData, setModelsData] = useState([]);
    const [filteredModelsData, setFilteredModelsData] = useState(modelsData);

    const getUserFromPathname = () => {
        const pathParts = location.pathname.split('/');
        const userParam = pathParts.find(part => part.startsWith('user='));
        return userParam ? userParam.split('=')[1] : null;
    };

    const idUser = Number(getUserFromPathname());

    console.log(modelsData)


    const handleSearchChange = (event) => {
        const searchValue = event.target.value;
        setSearchTerm(searchValue);

        const filteredData = modelsData.filter((model) =>
            model.name.toLowerCase().includes(searchValue.toLowerCase())
        );

        setFilteredModelsData(filteredData);
    };

    useEffect(() => {
        getAllModels(idUser, setModelsData, setFilteredModelsData);
    }, []);

    return (
        <div className={styles.wrapperListOfModels}>
            <SearchInput
                placeholder="Buscar modelo de requisição"
                searchTerm={searchTerm}
                handleSearchChange={handleSearchChange}
                maxWidth="356px"
            />

            {
                modelsData.length == 0 ?
                    <div className={styles.containerTextUserFalse}>
                        <p>Atualmente, não foi feita nenhuma requisição de exames.</p>
                    </div>
                    :
                    <p></p>
            }
        </div>
    )
}