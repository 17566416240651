import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

import styles from "./styles.module.css";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";

interface BoxVideoProps {
  sidebarCollapse: boolean;
}

export function BoxVideo({ sidebarCollapse }: BoxVideoProps) {
  const isMaxWidth1545 = useMediaQuery({ query: "(max-width: 1545px)" });
  const isMaxWidth1490 = useMediaQuery({ query: "(max-width: 1490px)" });
  const isMaxWidth1460 = useMediaQuery({ query: "(max-width: 1460px)" });
  const isMaxWidth1400 = useMediaQuery({ query: "(max-width: 1400px)" });

  const boxVideoStyle: React.CSSProperties =
    isMaxWidth1400 && !sidebarCollapse
      ? { height: "110px" }
      : isMaxWidth1460 && !sidebarCollapse
        ? { height: "130px" }
        : isMaxWidth1490 && !sidebarCollapse
          ? { height: "150px" }
          : isMaxWidth1545 && !sidebarCollapse
            ? { height: "180px" }
            : { height: "240px" };
  const h2BoxVideoStyle: React.CSSProperties =
    isMaxWidth1400 && !sidebarCollapse
      ? { fontSize: "0.8rem" }
      : isMaxWidth1460 && !sidebarCollapse
        ? { fontSize: "0.9rem" }
        : {};

  return (
    <div className={styles.wrapper}>
      <div className={styles.container_video} style={boxVideoStyle}>
        {/* <iframe
          width="560"
          height="315"
          className={styles.video}
          src="https://player-vz-fb154304-6eb.tv.pandavideo.com.br/embed/?v=c79a13ef-c847-49ba-965c-62a1cad4595c"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe> */}
      </div>

      <div className={styles.content}>
        <h2 style={h2BoxVideoStyle}>
          Acesso Móvel e Flexível às Informações:{" "}
        </h2>
        <h2 style={h2BoxVideoStyle}>
          Conecte-se a Seu Consultório e Pacientes de qualquer lugar
        </h2>

        <p
          style={
            isMaxWidth1400 && !sidebarCollapse
              ? { fontSize: "0.7rem" }
              : isMaxWidth1460 && !sidebarCollapse
                ? { fontSize: "0.8rem" }
                : {}
          }
        >
          A Serodonto reconhece a importância da mobilidade e flexibilidade no
          mundo atual e oferece a solução "Acesso Móvel e Flexível às
          Informações" para simplificar a comunicação e a gestão de consultórios
          odontológicos, mesmo quando os profissionais estão fora do
          consultório. Com o aplicativo Serodonto, tanto dentistas quanto
          pacientes podem acessar informações cruciais sobre tratamentos e
          exames a qualquer momento e de qualquer lugar, fortalecendo a conexão
          entre eles e a clínica, resultando em uma melhor experiência e
          acompanhamento dos tratamentos. Ao adotar essa solução inovadora, é
          possível transformar a maneira como os consultórios são gerenciados,
          proporcionando maior comodidade, rapidez e eficiência no atendimento.
        </p>

        <a
          href="https://api.whatsapp.com/send?phone=5512983206773&text=Estou%20interessado%20em%20saber%20de%20mais%20sobre%20o%20Serodonto!"
          target="_blank"
          className={styles.button}
          style={
            isMaxWidth1400 && !sidebarCollapse ? { fontSize: "0.7rem" } : {}
          }
        >
          Saiba mais
          <ArrowTopRightOnSquareIcon width={20} color="#fff" />
        </a>
      </div>
    </div>
  );
}
